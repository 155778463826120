import React from "react";
import cx from "classnames";
import {
  defaultSpellFields, getSpellLevelProperty,
  getSpellSchool,
  ISpell,
} from "models/Spell"
import {
  SpellCasting,
  SpellClasses,
  SpellComponents,
  SpellDamage,
  SpellDuration,
  SpellLevel,
  SpellRange,
  SpellSpecialCharacteristics,
  SpellType,
} from "components/ui/spell/SpellComponents";
import Chip from "components/ui/chip/Chip";
import IconBrew from "components/ui/icons/IconBrew";
import styles from "components/ui/spell/SpellCard.module.scss";

interface Props {
  spell: ISpell;
  className?: string;
}

const SpellCard = ({
  spell: { data: d, name, fields, img },
  className,
}: Props) => {
  const {
    level,
    actionType,
    school,
    save,
    activation,
    components,
    materials,
    range,
    target,
  } = d;
  const { classes, damage, homebrew, subclasses } =
    fields || defaultSpellFields;
  return (
    <div className={cx(styles.content, className)}>
      <blockquote>
        <div className={styles.header}>
          <SpellLevel level={level} className={styles.rune} large>
            {img && (
              <img
                alt="spell-icon"
                src={`https://foundry.coinstone.app/${img}`}
                className={styles.icon}
              />
            )}
          </SpellLevel>
          <div className={styles.headerText}>
            <h3 className={styles.spellHeader}>{name}</h3>
            <Chip className={styles.chip}>
              {getSpellSchool(school)} - {getSpellLevelProperty(level, 'label')}
            </Chip>
          </div>
          <SpellSpecialCharacteristics
            components={components}
            condensed={false}
            // className={styles.item}
          />
          {homebrew && <IconBrew className={styles.brew} />}
        </div>
      </blockquote>
      <div className={styles.properties}>
        <SpellCasting
          activation={activation}
          condensed={false}
          className={styles.item}
        />
        <SpellRange
          range={range}
          target={target}
          condensed={false}
          className={styles.item}
        />
        <SpellDuration data={d} className={styles.item} />
        <SpellComponents
          components={components}
          materials={materials}
          condensed={false}
          className={styles.item}
        />
        <SpellType
          actionType={actionType}
          save={save}
          condensed={false}
          className={styles.item}
        />

        <SpellDamage
          damage={damage}
          condensed={false}
          className={styles.itemFull}
        />
      </div>
      <blockquote>
        <p dangerouslySetInnerHTML={{ __html: formatDescription(d.description.value) }} />
      </blockquote>

      {classes && classes.length > 0 && (
        <>
          <hr />
          <blockquote>
            <br />
            <SpellClasses classes={classes} className={styles.item} />
            {subclasses.length > 0 && (
              <SpellClasses
                classes={subclasses}
                label={"Subclasses"}
                theme="secondary"
                className={styles.item}
              />
            )}
            <br />
          </blockquote>
        </>
      )}
    </div>
  );
};

const formatDescription = (description: string) => {
  return description
    .split("[[/r ").join("<span class='SpellCardRoll'>")
    .split("]]").join("</span>")
};

export default SpellCard;
