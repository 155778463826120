import React from "react";
import { graphql } from "gatsby";
import SpellCard from "components/ui/spell/SpellCard";
import { motion } from "framer-motion";
import { ISpell } from "models/Spell";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import Button from "components/ui/button/Button";
import SEO from "components/seo"

interface TemplateProps {
  data: { spell: ISpell; list: { edges: { node: ISpell }[] } };
}

const SpellPageTemplate = ({ data }: TemplateProps) => {
  return (
    <AppContentContainer>
      <SEO title={data.spell.name} />
      <Button to={"/spells"} theme="primary" variant="empty">
        {"< "}&nbsp;Back to Spells
      </Button>
      <motion.div transition={transition} {...animation}>
        <SpellCard spell={data.spell} />
      </motion.div>
    </AppContentContainer>
  );
};
const transition = {
  type: "spring",
  delay: 0,
  stiffness: 500,
  damping: 60,
  mass: 1,
};
const animation = {
  initial: {
    opacity: 0,
    scale: 1.3,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 1.3,
  },
};

export const pageQuery = graphql`
  query($slug: String!) {
    spell: spellsJson(fields: { slug: { eq: $slug } }) {
      name
      data {
        level
        school
        description {
          value
        }
        activation {
          cost
          type
          condition
        }
        duration {
          value
          units
        }
        target {
          value
          units
          type
        }
        range {
          value
          units
        }
        components {
          value
          vocal
          somatic
          material
          ritual
          concentration
        }
        materials {
          value
          consumed
          cost
          supply
        }
        actionType
        save {
          ability
        }
      }
      img
      fields {
        slug
        damage
        classes
        subclasses
        homebrew
      }
    }
  }
`;

export default SpellPageTemplate;
