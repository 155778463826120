import React from "react";
import classnames from "classnames";
import styles from "./Button.module.scss";
import { Link } from "gatsby";

export interface TButton {
  /** Alternative color variations */
  theme?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger";

  /** CSS class to be applied to the button */
  className?: string;

  /** Should the button be disabled */
  disabled?: boolean;

  /** Button type */
  type?: "button" | "submit" | "reset";

  /** Should the corners of the button should be rounded */
  rounded?: boolean;

  /** Should the button be filled in (default) or outlined (ghost) or empty */
  variant?: "filled" | "outlined" | "empty";

  /** Alternative HEX color value for background of the ghost (non-filled) button  */
  altBg?: string;
  children?: React.ReactNode;
  /** React DOM Link target */
  to?: string;
  /** Normal href attribute */
  href?: string;
  /** Href target attribute (e.g. '_blank') */
  target?: string;
  /** Callback function triggered on click */
  onClick?: (e: any) => void;
}

const Button = (props: TButton) => {
  if (props.to) {
    return <Link to={props.to || ""}> <ButtonBase {...props} /></Link>;
  }
  if (props.href) {
    console.log(props.href);
    return (
      <a href={props.href || ""} target={props.target || "_blank"}>
        <ButtonBase {...props} />
      </a>
    );
  }
  return <ButtonBase {...props} />;
};

const ButtonBase = ({
  className,
  onClick,
  disabled = false,
  type = "button",
  theme = "default",
  variant = "filled",
  rounded,
  altBg,
  children,
}: TButton) => (
  <button
    className={classnames(
      styles.button,
      theme && styles[theme],
      variant && styles[variant],
      rounded && styles.rounded,
      className
    )}
    disabled={disabled}
    onClick={onClick ? e => onClick(e) : () => {}}
    type={type}
    style={{ background: altBg }}
  >
    {children}
  </button>
);

export default Button;
